@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

body {
  font-family: "Press Start 2P", cursive;
  background-color: white;
  color: white;
  overflow: hidden;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

#text {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  cursor: pointer;
  white-space: nowrap;
}

#social-div {
  margin-top: 2rem;
  font-size: 1rem;
  position: relative;
}

#social {
  margin-top: 2rem;
  font-size: 1rem;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  flex-direction: column;
  gap: 16px;
}

#social a {
  color: #000;
  text-decoration: none;
}

#social a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  #text {
    white-space: normal;
    font-size: 2rem;
  }
}